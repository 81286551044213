* {
  box-sizing: border-box;
  font-family: 'Helvetica Neue Light', sans-serif;
}

body {
  overflow-x: hidden;
  margin: 0;
}

header {
  background-image: url('./photos/cupcakes2.jpeg');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
