.page-title {
  letter-spacing: 4px;
  text-transform: uppercase;
  font-style: inherit;
  font-weight: 300;
  font-family: Garamond;
}

.header-container {
  display: block;
  background-image: url('../../photos/cupcakes2.jpeg');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-width: 100vw;
  margin-left: -0.5rem;
}
